@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.spacerMobile {
  display: block;

  @media screen and (min-width: $tablet-min-breakpoint) {
    display: none;
  }
}

.spacerTablet {
  display: none;

  @media screen and (min-width: $tablet-min-breakpoint) {
    display: block;
  }

  @media screen and (min-width: $desktop-min-breakpoint) {
    display: none;
  }
}

.spacerDesktop {
  display: none;

  @media screen and (min-width: $desktop-min-breakpoint) {
    display: block;
  }
}
